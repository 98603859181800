<template>
  <base-info editPath="groupEdit" :baseInfoList="baseInfoList" :detailInfo="userInfo"></base-info>
</template>

<script>
import BaseInfo from '../BaseInfo'
export default {
  created () {
    // const res = await getUserInfo();
    // if(res.status === 200) {
    //   this.userInfo = res.data.data
    //   localStorage.setItem("userInfo",JSON.stringify(this.userInfo))
    // }
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
  },
  data(){
    return {
      userInfo:{},
      baseInfoList:[
        {
          title:"项目公司名称",
          field:"name"
        },
        {
          title:"管理员姓名",
          field:"people_name"
        },
        {
          title:"联系电话",
          field:"phone_number"
        },
        {
          title:"总包部数量",
          field:"company_num"
        },
        {
          title:"项目部数量",
          field:"department_num"
        },
        {
          title:"监督岗数量",
          field:"sentry_num"
        },
      ],
    }
  },

  components:{
    BaseInfo
  }
}
</script>

<style scoped>

</style>
