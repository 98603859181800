<template>
  <right-content>
    <div class="wrapper">
      <el-button class="editBtn" type="mini" @click="editInfo">修改信息</el-button>
      <div class="baseInfo">
        <div class="info-image">
          <img :src="userInfo.img" v-if="userInfo.img">
          <img v-else src="@/assets/images/logo.png">
        </div>
        <div class="info-content">
          <el-descriptions :column="2" border>
            <el-descriptions-item :key="index" v-for="(item,index) in baseInfoList">
              <template slot="label">
                {{ item.title }}
              </template>
              {{userInfo[item.field] }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
    </div>
  </right-content>
</template>

<script>

import RightContent from '../commonViews/RightContent'
export default {
  components: { RightContent },
  data(){
    return {
      userInfo:"",
    }
  },
  methods:{
    editInfo(){
      this.$router.push({
        path:this.editPath,
        query:{
          info:JSON.stringify(this.userInfo)
        }
      })
    }
  },
  watch: {
    detailInfo:{
      handler(val){
        this.userInfo = val;
      },
      deep:true,
      immediate:true
    }
  },
  props:{
    editPath:{
      type:String
    },
    detailInfo:{
      type:Object
    },
    baseInfoList:{
      type:Array,
      default(){
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.wrapper {
  height: 100vh;
  position: relative;
  border-radius: 10px;
  button{
    margin: 10px;
    background-color: red;
    color: #fff;
    padding: 8px 25px;
    border-radius: 5px;
  }
  .baseInfo{
    margin: 100px 200px;
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    .info-image{
      margin-bottom: 50px;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .info-content{
      margin-top: 30px;
      width: 100%;
    }
  }

}
::v-deep .el-descriptions-item__label {
  width: 200px;
  height: 44px;
  background: #F3F3F3;
  border: 1px solid #DCDCDC
}
::v-deep .el-descriptions-item__content{
  width: 350px;
}
</style>
